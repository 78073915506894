import store from "@/store";

function getTitle(vm: any) {
  console.log('router',vm.$router);
  console.log('route',vm.$route);
  
    return vm.$router.currentRoute.value.meta.title;
}

function getMenuActive(vm: any) {
    return vm.$router.currentRoute.value.meta.menuId;
}

function getFocus() {
    const el = document.getElementById('focus');
    if (el) {
        el.focus();
    }
}

export const titleMixin = {
    created() {
        // const title = getTitle(this);
        // document.title = title ? title + ' - 面试系统' : '面试系统';
        document.title = '童车社区'
        // store.dispatch("MenuActive/updateState", getMenuActive(this));
        // if (title === '首页') {
        //     store.dispatch("Crumbs/updateState", '');
        //     return;
        // }
        // store.dispatch("Crumbs/updateState", title);
        // getFocus();
    },
    activated() {
        // const title = getTitle(this);
        // document.title = title ? title + ' - 面试系统' : '面试系统';
        // store.dispatch("MenuActive/updateState", getMenuActive(this));
        // if (title === '首页') {
        //     store.dispatch("Crumbs/updateState", '');
        //     return;
        // }
        // store.dispatch("Crumbs/updateState", title);
        // getFocus();
    }
}