
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { getToken, saveToken, saveUser } from "@/https";
import {
  banners,
  incrementTag,
  typesTags,
  visitorsLogin,
  userTypesTags,
} from "@/https/apis";
import { Tag, Type } from "@/typing";
import { TypeData } from "@/typing/indexPage";
import { log } from "console";
import { deepClone } from "@/utils/utils";
import { titleMixin } from "../../mixins/titleMixin";
import { useStore } from "vuex";

@Options({
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [titleMixin],
})
export default class Index extends Vue {
  modules = [Pagination, Scrollbar, A11y];
  user = null;
  types: TypeData[] = [];
  banners = [];
  loginStore = useStore();
  async created() {
    this.user = JSON.parse(sessionStorage.getItem("user"));

    // if (!getToken()) {
    //   await visitorsLogin().then((res) => {
    //     saveToken(res.data.token);
    //     saveUser(res.data.user);
    //   });
    // }
    banners().then((res) => {
      this.banners = res.data;
    });
    this.initTypes();
  }

  initTypes() {
    if (this.user) {
      userTypesTags().then((res) => {
        this.types = res.data;
        // 排序点击数最多的三个图标
        this.types.forEach((item) => {
          item.tag_heats = deepClone(item.tags);
          item.tag_heats.sort((a, b) => {
            return a.pivot.num > b.pivot.num ? 1 : -1;
          });
          if (item.tag_heats.length > 3) {
            item.tag_heats = item.tag_heats.slice(-3);
          }
        });
      });
    } else {
      typesTags().then((res) => {
        this.types = res.data;
        // 排序点击数最多的三个图标
        this.types.forEach((item) => {
          item.tag_heats = deepClone(item.tags);
          item.tag_heats.sort((a, b) => {
            return a.pivot.num > b.pivot.num ? 1 : -1;
          });
          if (item.tag_heats.length > 3) {
            item.tag_heats = item.tag_heats.slice(-3);
          }
        });
      });
    }
  }

  tagClick(type_id: number, tag: Tag) {
    incrementTag(type_id, { tag_id: tag.id }).then((res) => {
      // this.initTypes()
      tag.pivot.num += 1;
    });
  }

  toStudy(id: number, type: any) {
    router.push({
      name: "Study",
      params: { id: id },
      query: {
        name: type.subtitle,
      },
    });
  }

  likesVisible = false;

  openLikes(type) {
    if (!getToken()) {
      this.loginStore.commit("Login/updateDialogVisible", true);
      return;
    }
    type.likesVisible = !type.likesVisible;
  }

  likes = [
    {
      imgUrl: require("@/assets/img/like_ic1.png"),
      text: "112",
    },
    {
      imgUrl: require("@/assets/img/like_ic2.png"),
      text: "45",
    },
    {
      imgUrl: require("@/assets/img/like_ic3.png"),
      text: "67",
    },
    {
      imgUrl: require("@/assets/img/like_ic4.png"),
      text: "935",
    },
    {
      imgUrl: require("@/assets/img/like_ic5.png"),
      text: "35",
    },
    {
      imgUrl: require("@/assets/img/like_ic6.png"),
      text: "86",
    },
    {
      imgUrl: require("@/assets/img/like_ic7.png"),
      text: "25",
    },
    {
      imgUrl: require("@/assets/img/like_ic8.png"),
      text: "78",
    },
  ];
}
