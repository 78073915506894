<template>
  <div class="box1200" style="position: relative;padding-bottom: 30px;">
    <div class="subject-title">
      <div class="subject-text">{{ datasss?.title }}</div>
      <div class="flex" v-if="currentQuestion !== -1" >
        <button
          :style="{
            backgroundImage:
              'url(' + require('@/assets/img/test-question/btn_bg.png') + ')',
          }"
          class="btn sign-out"
          @click="Quit()"
        >
        Quit Learning
        </button>
      </div>
    </div>
    <template v-if="currentQuestion !== -1">
      <div class="flex marginTop30 flex-center">
        <img :src="datasss.img" alt="" class="subject-img" />
        <div class="subject-answer">
          <!-- 选择题 start -->
          <template v-if="datasss.type === 'choice'">
            <div class="">
              <!--              <div class="choice-item choice-correct">-->
              <!--                A.11111-->
              <!--                <img-->
              <!--                    src="@/assets/img/ic_correct.png"-->
              <!--                    alt=""-->
              <!--                    class="choice-item-img"-->
              <!--                />-->
              <!--              </div>-->
              <template v-if="!datasss.user_questions">
                <div
                  class="choice-item choice-cursor"
                  v-for="item in datasss.answers"
                  :key="item.id"
                  @click="choice(item.id)"
                >
                  {{ item.title }}
                </div>
              </template>

              <template v-if="datasss.user_questions">
                <div
                  class="choice-item"
                  :class="[
                    datasss.answer_id === item.id ? 'choice-correct' : '',
                    datasss.user_questions.answer_id === item.id
                      ? 'choice-select'
                      : '',
                  ]"
                  v-for="item in datasss.answers"
                  :key="item.id"
                >
                  {{ item.title }}
                  <img
                    v-if="datasss.answer_id === item.id"
                    src="@/assets/img/ic_correct.png"
                    alt=""
                    class="choice-item-img"
                  />
                </div>
              </template>
            </div>
          </template>

          <!-- 选择题 end -->

          <!-- 判断题 start -->

          <template v-if="datasss.type === 'judge'">
            <div v-if="!datasss.user_questions">
              <div class="select-answer">Please select the correct answer</div>
              <div class="judgmental-question">
                <img
                  src="@/assets/img/test-question/error_grey.png"
                  alt=""
                  class="subject-choice"
                  @click="judge(false)"
                  style="cursor: pointer"
                />

                <img
                  src="@/assets/img/test-question/correct_grey.png"
                  alt=""
                  class="subject-choice"
                  @click="judge(true)"
                  style="cursor: pointer"
                />
              </div>
            </div>

            <div v-if="datasss.user_questions">
              <div class="judgmental-question">
                <template v-if="datasss.user_questions.judge_answer">
                  <img
                    src="@/assets/img/test-question/error_grey.png"
                    alt=""
                    class="subject-choice"
                  />

                  <img
                    src="@/assets/img/test-question/correct_nor.png"
                    alt=""
                    class="subject-choice"
                  />
                </template>
                <template v-if="!datasss.user_questions.judge_answer">
                  <img
                    src="@/assets/img/test-question/error_nor.png"
                    alt=""
                    class="subject-choice"
                  />

                  <img
                    src="@/assets/img/test-question/correct_grey.png"
                    alt=""
                    class="subject-choice"
                  />
                </template>
              </div>
              <div
                class="answer-correct"
                v-if="
                  datasss.judge_answer === datasss.user_questions.judge_answer
                "
              >Correct Answer
                
              </div>
              <div class="answer-error" v-else>
                Wrong Answer
              </div>

              <div class="standard-answer">
                Standard Answer： <b>{{ datasss.judge_answer ? "CORRECT" : "ERROR" }}</b>
              </div>
            </div>
          </template>
          <!-- 判断题 end-->

          <button  v-if="datasss.user_questions"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/img/test-question/btn_bg.png') + ')',
            }"
            class="btn"
            @click="nextQuestion()"
          >
           Next Question
          </button>


        </div>
      </div>

      <div class="analysis-box" v-if="datasss.user_questions">
        <div class="analysis-title">Best Analysis</div>
        <div class="analysis-answer">
          {{ datasss.analysis }}
        </div>
      </div>
    </template>
  </div>

  <div v-if="currentQuestion === -1" class="complete-box">
    <img src="@/assets/img/complete.png" alt="" class="complete-img">
    <h2 class="marginTop30">CONGRATULATIONS!</h2>
    <h3 style="margin-top: 20px;margin-bottom: 20px;">All answers have been completed</h3>
  <div class="flex">
    <button 
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/test-question/btn_bg.png') + ')',
        }"
        style="margin: unset;"
        class="btn sign-out"
        @click="again()"
      >
      RELEARN
    </button>
    <button
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/test-question/btn_bg.png') + ')',
      }"
      class="btn sign-out"
      style="margin: unset;"
      @click="Quit()"
    >
    QUIT LEARNING
    </button>
  </div>
  </div>
</template>

<script lang="ts">
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import {
  childtypeQuestions,
  questionsAnswers,
  clearAnswers,
} from "@/https/apis";
import { Question } from "@/typing";

@Options({
  components: {},
  computed: {
    datasss(): Question {
      let question: Question = this.lists[this.currentQuestion];
      if (question === undefined) {
        question = {
          analysis: "",
          answer_id: null,
          child_type_id: 7,
          created_at: "",
          id: 2,
          img: "",
          judge_answer: 1,
          parent_type_id: 0,
          title: "",
          type: "",
          updated_at: "",
          user_questions: null,
        };
      }
      return question;
    },
  },
})
export default class TestQuestion extends Vue {
  datasss: Question;

  lists: Question[] = [];
  currentQuestion = 0;

  setCurrentQuestion(value: number): void {
    sessionStorage.setItem("currentQuestion", String(value));
    this.currentQuestion = value;
  }

  getCurrentQuestion(): number {
    return Number(sessionStorage.getItem("currentQuestion"));
  }

  async getQuestions() {
    let id = this.$route.params.id;
    await childtypeQuestions(id).then((res) => {
      this.lists = res.data;
    });
  }

  async created() {
    await this.getQuestions();
    console.log(sessionStorage.getItem("refreshCurrentQuestion"));
    if (sessionStorage.getItem("refreshCurrentQuestion") === "true") {
      this.setCurrentQuestion(
        this.lists.indexOf(
          this.lists.find((item) => {
            return !item.user_questions;
          })
        )
      );

      sessionStorage.setItem("refreshCurrentQuestion", String(false));
    } else {
      this.currentQuestion = this.getCurrentQuestion();
    }
  }

  again() {
    clearAnswers(this.$route.params.id).then((res) => {
      this.getQuestions();
      this.setCurrentQuestion(0);
    });
  }

  Quit() {
    // router.push({ path: "/index" });
    router.back()
  }

  nextQuestion() {
    if (this.currentQuestion < this.lists.length - 1) {
      this.setCurrentQuestion(this.currentQuestion + 1);
    } else {
      this.setCurrentQuestion(-1);
    }
  }

  judge(answer: boolean) {
    let data = {
      answers: [
        {
          question_id: this.datasss.id,
          judge_answer: answer,
        },
      ],
    };
    questionsAnswers(data).then((res) => {
      this.getQuestions();
    });
  }
  choice(id: number) {
    let data = {
      answers: [
        {
          question_id: this.datasss.id,
          answer_id: id,
        },
      ],
    };
    questionsAnswers(data).then((res) => {
      this.getQuestions();
    });
  }
}
</script>

<style scoped>
.subject-title {
  font-size: 16px;
  padding-top: 30px;
  position: relative;
  display: flex;
  margin-top: 120px;
  justify-content: space-between;
}

.subject-text {
  width: 65%;
  font-size: 22px;
  font-weight: 700;
}

.sign-out {
 margin-left: 20px!important;
}

.subject-img {
  width: 48%;
}

.select-answer{
  font-size: 25px;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 600;
}

.choice-select {
  background-color: #f9bf66 !important;
  box-shadow: 0 5px 0 rgba(248,172,54,.3)!important;
}

.choice-cursor {
  cursor: pointer;
}

.btn {
  width: 140px;
  height: 44px;
  background-size: 100% 100%;
  border: 0;
  font-size: 14px;
  color: #623f13;
  font-weight: 600;
  background-color: transparent;
  margin: 0 auto;
  display: block;
  cursor: pointer;
}

.subject-answer {
  padding-left: 60px;
  width: 34%;
}

.choice-item {
  /* box-shadow: inset 0px -1px 4px 0px rgba(255, 255, 255, 0.47), 0 4px 0 #273441 ; */
  box-shadow: 0 5px 0 #ddd;
  background: #f6f6f6;
  height: 54px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  border-radius: 50px;
  margin-bottom: 30px;
  position: relative;
}

.choice-item-img {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
}
.judgmental-question {
  display: flex;
  justify-content: center;
}
.answer-correct,.answer-error{
  text-align: center;
  margin-top: 20px;
  font-size: 26px;
  font-weight: 600;
}
.answer-correct {
  color: #4cbb2e;
 
}

.answer-error{
  color: red;
}

.subject-choice {
  width: 70px;
  margin: 0 20px;
  cursor: pointer;
}


.subject-choice:hover{
  transform: scale(1.1);
}


.standard-answer {
  margin: 15px 0 25px;
  text-align: center;
}

.analysis-box {
  background: #f0f0f0;
  padding: 30px;
  margin-top: 40px;
}

.analysis-title {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
}

.analysis-answer{
  font-size: 18px;
}

.complete-box{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 20px;
  padding-top: 50px;
}

.complete-img{
  width: 300px;
}
</style>
