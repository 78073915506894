
import router from "@/router";
import {Options, Vue} from "vue-class-component";
import {childtypes} from "@/https/apis";
import {TypeData} from "@/typing/indexPage";


@Options({
  components: {},
})
export default class Study extends Vue {
  list = [
    {
      title: "道路交通标志",
      success: true,
      progress: "100",
    },
    {
      title: "道路交通标志",
      success: false,
      progress: "90",
    },
    {
      title: "道路交通标志",
      success: false,
      progress: "0",
    },
    {
      title: "道路交通标志",
      success: false,
      progress: "0",
    },
  ];

  childTypeList: TypeData[] = []

  created() {
    childtypes(this.$route.params.id).then(res => {
      this.childTypeList = res.data
    })
  }

  toIndex() {
    router.push({
       name: 'Index'
    })
  }

  testQuestion(item : TypeData) {
    if (item.status == 'coming_soon'){
      return ;
    }
    let id = item.id
    sessionStorage.setItem('refreshCurrentQuestion',String(true))
    console.log(sessionStorage.getItem('refreshCurrentQuestion'))
    router.push({name: "TestQuestion", params: {id: id}});
  }
}
