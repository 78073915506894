<template>
  <div>
    <img src="@/assets/img/personal_bg.png" alt="" class="personal-bg" />
    <div class="account-box">
      <div class="title">Account information</div>
      <div class="account-list"><span class="text">account:</span>152588494@163.com</div>
      <div class="account-list">
        <span class="text">password:</span>
        <!-- <input type="text" placeholder="1579855" /> -->
        zfgb15455
      </div>
      <el-button  class="btn-sign">Change Password</el-button>
    </div>
  </div>
</template>

<script lang="ts">
import router from "@/router";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class PersonalCenter extends Vue {}
</script>

<style scoped>
.personal-bg {
  width: 100%;
}

.account-box {
  width: 350px;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 20px 30px;
  margin-top: -100px;
  position: relative;
  z-index: 1;
  border-radius: 6px;
}

.title {
  font-size: 18px;
  /* text-transform: uppercase; */
  margin-bottom: 30px;
}

.account-list{
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.text{
  font-size: 14px;
  color: #999;
  margin-right: 20px;
}


.btn-sign {
  background: #273441;
  border: 0;
  padding: 6px 30px;
  box-shadow: inset 0px -1px 4px 0px rgba(255, 255, 255, 0.47), 0 4px 0 #273441 !important;
  border-radius: 24px;
  color: #fff;
  margin: 30px auto 0;
}
</style>
