<template>
  <div class="home-page-box">
    <div class="swiper-box">
      <swiper
        :slides-per-view="1"
        :space-between="50"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="img in banners" :key="img.id">
          <img :src="img.image" alt="" class="carousel-img" />
          <!-- <img src="@/assets/img/background.png" alt="" class="carousel-img" /> -->
        </swiper-slide>
      </swiper>
      <div class="swiper-bottom-bg">
        <img src="@/assets/img/mainbg1.png" alt="" />
      </div>
    </div>
    <!-- <div class="box1200 flex flex-center kid-slogan">
      <img
        src="@/assets/img/kids_slogan_img.png"
        alt=""
        class="kids-slogan-img"
      />
      <div class="kids-slogan-text">Baby Driving School</div>
    </div> -->

    <div class="test-box">
      <div class="box1200 test-main">
        <div class="flex flex-between">
          <div
            class="driving-theory"
            v-for="(type, index) in types"
            :key="type.id"
            @click.stop="toStudy(type.id, type)"
          >
            <img :src="type.img" alt="" class="driving-img" />
            <div class="flex flex-end like-box">
              <!-- <div class="white-bg">
                <img
                  src="@/assets/img/ic_collection.png"
                  alt=""
                  class="white-bg-img"
                />
              </div> -->
              <div
                class="flex flex-between flex-center"
                @click.stop="openLikes(type)"
              >
                <template v-for="(tag, i) in type.tag_heats" :key="i">
                  <div
                    class="white-bg white-bg-big"
                    v-if="i === type.tag_heats.length - 1"
                  >
                    <img :src="tag.img" alt="" class="white-bg-img" />
                  </div>
                  <div class="white-bg" v-else>
                    <img :src="tag.img" alt="" class="white-bg-img" />
                  </div>
                </template>
              </div>

              <div class="likes-box" v-show="type.likesVisible" @click.stop>
                <div
                  v-for="tag in type.tags"
                  :key="tag.id"
                  class="likes-item"
                  @click.stop="tagClick(type.id, tag)"
                >
                  <img :src="tag.img" alt="" class="likes-img" />
                  <span class="likes-num">{{ tag.pivot.num }}</span>
                </div>
              </div>
            </div>
            <div class="driving-title">
              {{ type.name }}
            </div>
            <div class="btn-box">
              <button class="btn-see">
                Go Explore
                <img
                  src="@/assets/img/ic_check.png"
                  alt=""
                  class="carousel-img"
                />
              </button>
              <div class="isPassed">
                <!-- <img
                  :src="
                    type.is_unlock == 1
                      ? require('@/assets/img/passed.png')
                      : types[index - 1]?.is_unlock == 1 ||
                        index===0
                      ? require('@/assets/img/unlock.png')
                      : require('@/assets/img/lock.png')
                  "
                  alt=""
                /> -->
                <img
                  :src="
                    type.user_unlock_type?.status == 3
                      ? require('@/assets/img/passed.png')
                      : type.user_unlock_type?.status == 2 || index === 0
                      ? require('@/assets/img/unlock.png')
                      : require('@/assets/img/lock.png')
                  "
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { getToken, saveToken, saveUser } from "@/https";
import {
  banners,
  incrementTag,
  typesTags,
  visitorsLogin,
  userTypesTags,
} from "@/https/apis";
import { Tag, Type } from "@/typing";
import { TypeData } from "@/typing/indexPage";
import { log } from "console";
import { deepClone } from "@/utils/utils";
import { titleMixin } from "../../mixins/titleMixin";
import { useStore } from "vuex";

@Options({
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [titleMixin],
})
export default class Index extends Vue {
  modules = [Pagination, Scrollbar, A11y];
  user = null;
  types: TypeData[] = [];
  banners = [];
  loginStore = useStore();
  async created() {
    this.user = JSON.parse(sessionStorage.getItem("user"));

    // if (!getToken()) {
    //   await visitorsLogin().then((res) => {
    //     saveToken(res.data.token);
    //     saveUser(res.data.user);
    //   });
    // }
    banners().then((res) => {
      this.banners = res.data;
    });
    this.initTypes();
  }

  initTypes() {
    if (this.user) {
      userTypesTags().then((res) => {
        this.types = res.data;
        // 排序点击数最多的三个图标
        this.types.forEach((item) => {
          item.tag_heats = deepClone(item.tags);
          item.tag_heats.sort((a, b) => {
            return a.pivot.num > b.pivot.num ? 1 : -1;
          });
          if (item.tag_heats.length > 3) {
            item.tag_heats = item.tag_heats.slice(-3);
          }
        });
      });
    } else {
      typesTags().then((res) => {
        this.types = res.data;
        // 排序点击数最多的三个图标
        this.types.forEach((item) => {
          item.tag_heats = deepClone(item.tags);
          item.tag_heats.sort((a, b) => {
            return a.pivot.num > b.pivot.num ? 1 : -1;
          });
          if (item.tag_heats.length > 3) {
            item.tag_heats = item.tag_heats.slice(-3);
          }
        });
      });
    }
  }

  tagClick(type_id: number, tag: Tag) {
    incrementTag(type_id, { tag_id: tag.id }).then((res) => {
      // this.initTypes()
      tag.pivot.num += 1;
    });
  }

  toStudy(id: number, type: any) {
    router.push({
      name: "Study",
      params: { id: id },
      query: {
        name: type.subtitle,
      },
    });
  }

  likesVisible = false;

  openLikes(type) {
    if (!getToken()) {
      this.loginStore.commit("Login/updateDialogVisible", true);
      return;
    }
    type.likesVisible = !type.likesVisible;
  }

  likes = [
    {
      imgUrl: require("@/assets/img/like_ic1.png"),
      text: "112",
    },
    {
      imgUrl: require("@/assets/img/like_ic2.png"),
      text: "45",
    },
    {
      imgUrl: require("@/assets/img/like_ic3.png"),
      text: "67",
    },
    {
      imgUrl: require("@/assets/img/like_ic4.png"),
      text: "935",
    },
    {
      imgUrl: require("@/assets/img/like_ic5.png"),
      text: "35",
    },
    {
      imgUrl: require("@/assets/img/like_ic6.png"),
      text: "86",
    },
    {
      imgUrl: require("@/assets/img/like_ic7.png"),
      text: "25",
    },
    {
      imgUrl: require("@/assets/img/like_ic8.png"),
      text: "78",
    },
  ];
}
</script>

<style scoped>
.home-page-box {
  /* margin-top: -120px; */
}

.swiper-box {
  position: relative;
}

.swiper-bottom-bg {
  position: absolute;
  left: 0;
  bottom: -1px;
  z-index: 1;
}

.swiper-bottom-bg img {
  width: 100%;
  vertical-align: bottom;
}

.swiper {
  width: 100%;
  height: 793px;
}

.carousel-img {
  width: 100%;
  height: 100%;
}

.driving-theory {
  width: 48%;
  background: #fff;
  border-radius: 33px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
}

.driving-img {
  width: 100%;
  height: 330px;
}

.like-box {
  position: relative;
  top: -25px;
  padding: 0 25px;
  z-index: 10;
}

.white-bg {
  width: 40px;
  height: 40px;
  margin-right: -7px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.white-bg-big {
  width: 50px;
  height: 50px;
  margin-right: 0;
}

.white-bg-big .white-bg-img {
  width: 22px;
  height: 22px;
}

.white-bg-img {
  width: 20px;
  height: 20px;
}

.likes-box {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: absolute;
  right: 30px;
  top: 60px;
  display: flex;
}

.likes-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.likes-img {
  width: 16px;
  height: 16px;
}

.likes-num {
  font-size: 12px;
  color: #999;
  margin-top: 4px;
}

.driving-title {
  min-height: 108px;
  padding: 0 20px 40px;
  font-size: 28px;
  font-weight: 600;
  color: #293744;
  box-sizing: border-box;
}

.btn-box {
  display: flex;
  padding: 0 20px 20px;
}

.btn-see {
  width: 224px;
  height: 54px;
  padding: 0 30px;
  background: linear-gradient(180deg, #24323f 0%, #394754 100%);
  border: 0;
  box-shadow: inset 0px -1px 4px 0px rgba(255, 255, 255, 0.47), 0 4px 0 #273441;
  border-radius: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #fff;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.btn-see > img {
  width: 24px;
  height: 24px;
}

.test-box {
  box-sizing: border-box;
  min-width: 0;
  /* position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  /* background-image: url(https://www.lego.com/cdn/cs/catalog/assets/blt6706b7a5558ba059/1/thumbnail.jpg); */
  background-repeat: repeat;
  background: url("~@/assets/img/mainbg2.png") no-repeat #bcd9e3 center bottom;
  overflow: hidden;
}

.test-main {
  margin: 100px auto;
}

.kid-slogan {
  padding: 20px 0;
}

.kids-slogan-img {
  width: 80px;
  margin-right: 30px;
}
.kids-slogan-text {
  font-size: 20px;
  font-weight: 600;
}

.isPassed {
  display: flex;
  flex: 1;
  justify-content: right;
  align-items: center;
  margin-right: 20px;
}
</style>
