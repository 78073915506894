<template>
  <div>
    <div
        class="bg"
        :style="{
        backgroundImage: 'url(' + require('@/assets/img/test/bg.png') + ')',
      }"
    >
   
      <div class="box1200" style="position:relative">
        <div :style="{
            backgroundImage:
              'url(' + require('@/assets/img/test-question/btn_bg.png') + ')',
          }" class="go-back" @click="toIndex()">
          Go Back
        </div>
        <div class="flex flex-center title">
          <div
              class="study-title"
              :style="{
              backgroundImage:
                'url(' + require('@/assets/img/test/title_bg.png') + ')',
            }"
          >
            <!--            初级知识-->
            {{ $route.query.name }}
          </div>
          <div class="study-text">
            <div>Overall progress<span class="study-text-span">22</span></div>
            <div>Ranking of learning progress exceeds<span class="study-text-span">50%</span></div>
          </div>
        </div>


        <div class="study-progress-box">

          <div
              class="study-item"
              :style="{
              backgroundImage:
                'url(' + require('@/assets/img/test/progress_bg.png') + ')',
              marginRight: (i%3===2)?'0%':'2%',
            }"
              v-for="(item,i) in childTypeList"
              :key="i"
              @click="testQuestion(item)"

          >

            <div class="progress-title">{{ item.name }}</div>
            <template v-if="item.status=='coming_soon'">
              <div style="display: flex;justify-content: center;position: absolute;bottom: 40px;width: 100%">
                <div style="width: 80%;height:40px;background-color: #FFF;align-items:center;display: flex;justify-content: center" >
                  <span>Coming Soon</span>
                </div>
              </div>

            </template>
            <template v-else>
              <img
                  src="@/assets/img/test/progress_success.png"
                  alt=""
                  class="progress-success"
                  v-if="item.answered_count === item.questions_count"
              />
              <el-progress
                  :percentage=" Math.ceil(item.answered_count*100 / item.questions_count)"
                  class="study-progress"
              ></el-progress>
            </template>


          </div>

        </div>

      </div>
      <!-- <img src="@/assets/img/test/bg.png" alt=""> -->
    </div>
  </div>

</template>

<script lang="ts">
import router from "@/router";
import {Options, Vue} from "vue-class-component";
import {childtypes} from "@/https/apis";
import {TypeData} from "@/typing/indexPage";


@Options({
  components: {},
})
export default class Study extends Vue {
  list = [
    {
      title: "道路交通标志",
      success: true,
      progress: "100",
    },
    {
      title: "道路交通标志",
      success: false,
      progress: "90",
    },
    {
      title: "道路交通标志",
      success: false,
      progress: "0",
    },
    {
      title: "道路交通标志",
      success: false,
      progress: "0",
    },
  ];

  childTypeList: TypeData[] = []

  created() {
    childtypes(this.$route.params.id).then(res => {
      this.childTypeList = res.data
    })
  }

  toIndex() {
    router.push({
       name: 'Index'
    })
  }

  testQuestion(item : TypeData) {
    if (item.status == 'coming_soon'){
      return ;
    }
    let id = item.id
    sessionStorage.setItem('refreshCurrentQuestion',String(true))
    console.log(sessionStorage.getItem('refreshCurrentQuestion'))
    router.push({name: "TestQuestion", params: {id: id}});
  }
}
</script>

<style scoped>


.bg {
  width: 100%;
  /* height: calc(100vh - 120px); */
  height: 100vh;
  padding-top: 120px;
  background-size: cover;
  position: relative;
}

.title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
}

.study-title {
  position: relative;
  width: 316px;
  height: 136px;
  padding: 0 16px;
  color: #b14c46;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  text-align: center;
}

.study-text {
  position: absolute;
  right: 0;
  font-size: 14px;
  color: white;
  text-align: right;
}

.study-text-span{
  font-weight: 600;
  padding-left: 10px;
  font-size: 22px;
}

.study-text > div {
  margin: 10px 0;
}

.study-progress-box {
  display: flex;
  flex-wrap: wrap;
  /*justify-content: space-between;*/
  margin-top: 40px;
}

.study-item {
  width: 32%;
  padding-bottom: 18%;
  height: 0;
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
}

.progress-success {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.progress-title {
  font-size: 24px;
  padding: 50px 40px 40px;
  color: #d3453d;
  text-align: center;
  font-weight: 600;
}

.study-progress {
  width: 80%;
  margin: 30px auto 0;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
}

::v-deep().el-progress-bar__outer {
  background-color: transparent;
  border: 1px solid #d2443c;
  height: 4px;
}

::v-deep().el-progress-bar__inner {
  background-color: #d3453d;
}

::v-deep().el-progress-bar {
  width: 100%;
}

::v-deep().el-progress__text {
  margin-left: 0;
  margin-top: 10px;
  color: #d3453d;
}

.go-back{
  width: 130px;
  height: 42px;
  background-size: 100% 100%;
  border: 0;
  font-size: 14px;
  color: #623f13;
  font-weight: 400;
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  z-index: 2;
  cursor: pointer;
}
</style>
