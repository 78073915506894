/**
 * 深克隆
 * @param obj 
 * @returns 
 */
export function deepClone (obj) {
  if (obj === null || typeof obj !== 'object') return obj
  const cpObj = obj instanceof Array ? [] : {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      cpObj[key] = deepClone(obj[key])
    }
  }
  return cpObj
}