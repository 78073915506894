
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import {
  childtypeQuestions,
  questionsAnswers,
  clearAnswers,
} from "@/https/apis";
import { Question } from "@/typing";

@Options({
  components: {},
  computed: {
    datasss(): Question {
      let question: Question = this.lists[this.currentQuestion];
      if (question === undefined) {
        question = {
          analysis: "",
          answer_id: null,
          child_type_id: 7,
          created_at: "",
          id: 2,
          img: "",
          judge_answer: 1,
          parent_type_id: 0,
          title: "",
          type: "",
          updated_at: "",
          user_questions: null,
        };
      }
      return question;
    },
  },
})
export default class TestQuestion extends Vue {
  datasss: Question;

  lists: Question[] = [];
  currentQuestion = 0;

  setCurrentQuestion(value: number): void {
    sessionStorage.setItem("currentQuestion", String(value));
    this.currentQuestion = value;
  }

  getCurrentQuestion(): number {
    return Number(sessionStorage.getItem("currentQuestion"));
  }

  async getQuestions() {
    let id = this.$route.params.id;
    await childtypeQuestions(id).then((res) => {
      this.lists = res.data;
    });
  }

  async created() {
    await this.getQuestions();
    console.log(sessionStorage.getItem("refreshCurrentQuestion"));
    if (sessionStorage.getItem("refreshCurrentQuestion") === "true") {
      this.setCurrentQuestion(
        this.lists.indexOf(
          this.lists.find((item) => {
            return !item.user_questions;
          })
        )
      );

      sessionStorage.setItem("refreshCurrentQuestion", String(false));
    } else {
      this.currentQuestion = this.getCurrentQuestion();
    }
  }

  again() {
    clearAnswers(this.$route.params.id).then((res) => {
      this.getQuestions();
      this.setCurrentQuestion(0);
    });
  }

  Quit() {
    // router.push({ path: "/index" });
    router.back()
  }

  nextQuestion() {
    if (this.currentQuestion < this.lists.length - 1) {
      this.setCurrentQuestion(this.currentQuestion + 1);
    } else {
      this.setCurrentQuestion(-1);
    }
  }

  judge(answer: boolean) {
    let data = {
      answers: [
        {
          question_id: this.datasss.id,
          judge_answer: answer,
        },
      ],
    };
    questionsAnswers(data).then((res) => {
      this.getQuestions();
    });
  }
  choice(id: number) {
    let data = {
      answers: [
        {
          question_id: this.datasss.id,
          answer_id: id,
        },
      ],
    };
    questionsAnswers(data).then((res) => {
      this.getQuestions();
    });
  }
}
